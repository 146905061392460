application.register("layout_header", class extends Stimulus.Controller {
    connect() {
        if (document.querySelector("#layout_nav") === null) {
            this.element.insertAdjacentHTML("afterend", `<div id="layout_nav" class="lib--drawer" data-target="lib-drawer.nav" data-action="scroll->lib-drawer#scroll"><div class="wrp_nav"><div class="wrp_nav_head"></div><div class="wrp_nav_body"></div></div></div>`);

            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").init();

            let layout_nav = document.querySelector("#layout_nav"),
                logo = this.element.querySelector(".elm_header_logo").outerHTML,
                nav = this.element.querySelector(".elm_header_nav").outerHTML;

            layout_nav.querySelector(".wrp_nav_head").insertAdjacentHTML("beforeend", logo);
            layout_nav.querySelector(".wrp_nav_body").insertAdjacentHTML("beforeend", nav);
        }
    }
});