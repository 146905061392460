const LibTabs = function (element, callback) {
    [...element.querySelectorAll(`[data-lib-tabs-nav]`)].map((nav) => [...nav.querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((selector, index) => {
        selector.addEventListener("click", (e) => {
            e.preventDefault();

            [...element.querySelectorAll(`[data-lib-tabs-nav]`)].forEach((elm) => {
                if(elm.closest(`[data-lib-tabs-nav]`).nodeName.toLowerCase() === "svg" && document.documentElement.classList.contains("ie")) {
                    return;
                }
                [...elm.closest(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((elm) => elm.classList.remove("state--active"));
                elm.querySelectorAll(`[data-lib-tabs-item="nav"]`)[index].classList.add("state--active");
            });

            [...element.querySelector(`[data-lib-tabs-area]`).children].forEach((elm) => {
                elm.classList.remove("state--active");
            });

            element.querySelector(`[data-lib-tabs-area]`).children[index].classList.add("state--active");

            if (callback) {
                callback(index);
            }
        })
    }));
};