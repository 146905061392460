application.register("comp_selection", class extends Stimulus.Controller {
    static get targets() {
        return ["tooltipImage", "tooltipBody", "tooltipOccupied", "tooltipReserved"]
    }

    connect() {
        let self = this;

        function replaceAll(originalString, find, replace) {
            return originalString.replace(new RegExp(find, 'g'), replace);
        }

        [...self.element.querySelectorAll("[data-tooltip]")].forEach((elm) => {
            let data = JSON.parse(elm.getAttribute("data-tooltip"));
            let html = self[data.target + "Target"].innerHTML;
            Object.keys(data).forEach((key) => {
                html = replaceAll(html, `{${key}}`, data[key]);
            });

            elm.addEventListener("click", (e) => {
                if (!document.documentElement.classList.contains("no-touch") && data.mobile !== false) {
                    LibDialog.show(`<div class="part_dialog part_dialog_basic flex--justify-center"><div class="wrp_dialog"><button class="elm_dialog_close icon--close" data-action="click->lib-dialog#hide"></button>${html}</div></div>`, () => loadStimulus(document.querySelector(".lib--dialog")));
                }
            });

            if (!document.documentElement.classList.contains("ie")) {
                elm.addEventListener("mousemove", (e) => {
                    if (document.documentElement.classList.contains("no-touch")) {
                        if (self.element.querySelector(".part_tooltip") === null) {
                            self.element.insertAdjacentHTML("beforeend", html);
                        }

                        let y = 0;

                        if (document.documentElement.classList.contains("has-scroll-smooth")) {
                            y = locomotive.scroll.instance.scroll.y;
                        }

                        self.element.querySelector(".part_tooltip").style.left = (e.clientX) + 'px';
                        self.element.querySelector(".part_tooltip").style.top = e.clientY + y - 16 + "px";
                        self.element.querySelector(".part_tooltip").style.display = "flex";
                    }
                }, true);

                elm.addEventListener("mouseleave", (e) => {
                    if (document.documentElement.classList.contains("no-touch") && self.element.querySelector(".part_tooltip") !== null) {
                        self.element.querySelector(".part_tooltip").remove();
                    }
                }, true);
            }
        });

        if (document.documentElement.classList.contains("ie")) {
            [...self.element.querySelectorAll(`image[data-href]`)].forEach((image) => {
                image.setAttribute("href",image.getAttribute("data-href"));
            })
        }
        else {
            [...self.element.querySelectorAll(`image[data-href]`)].forEach((image) => {
                image.closest("svg").classList.add("lazyload");
            })
        }
    }
});