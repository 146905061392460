let dynamicControllers = ["part_ui_input", "part_ui_select", "part_ui_wsw", "part_cookieconsent", "part_form_cookieconsent"];
let dynamicActions = [[`a[href^="#"]`, "click->lib#anchor"], [`.part_ui_btn, .part_ui_link`, "click->lib#ripple"]];
let locomotive;

const application = Stimulus.Application.start(document.documentElement, {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
});

importScript.used = [];
importScript.loaded = [];

if ('serviceWorker' in navigator && location.protocol === "https:") {
    window.addEventListener('load', function () {
        if (!document.documentElement.classList.contains("no-sw")) {
            navigator.serviceWorker.register('/sw.js').catch(function (e) {
                console.error('Error during service worker registration:', e);
            });
        } else {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    for (let registration of registrations) {
                        registration.unregister()
                    }
                }
            });
        }
    });
}

function locomotiveOnScroll() {
    document.documentElement.classList.remove("is--header-not-top");

    locomotive.on('scroll', (e) => {
        let position = e.delta.y;

        if (position > 100) {
            document.documentElement.classList.add("is--header-not-top");
        } else {
            document.documentElement.classList.remove("is--header-not-top");
        }
    });
}

(function locomotiveScroll() {
    bodyLoaded(function() {
        if(!document.documentElement.classList.contains("ie")) {
            locomotive = new LocomotiveScroll({
                el: document.getElementById('layout_scroll'),
                smooth: true,
                getSpeed: true,
                getDirection: true,
                inertia: 0.9
                // smoothMobile: true
            });

            setTimeout(function () {
                locomotive.update();
            },1000);

            locomotiveOnScroll();
        }

        let lastScrollTop = 0;

        window.addEventListener('scroll', function(e) {
            let position = document.documentElement.scrollTop;

            if (position > 5) {
                document.documentElement.classList.add("is--header-not-top");
            } else {
                document.documentElement.classList.remove("is--header-not-top", "is--header-pinned", "is--header-unpinned");
            }

            if (position > lastScrollTop){
                document.documentElement.classList.remove("is--header-pinned");
                document.documentElement.classList.add("is--header-unpinned");
            } else {
                document.documentElement.classList.remove("is--header-unpinned");
                document.documentElement.classList.add("is--header-pinned");
            }

            lastScrollTop = position <= 0 ? 0 : position;
        });
    })
})();

if (document.body.classList.contains("body--preload")) {
    cssLoaded(function () {
        document.body.classList.remove("body--preload");
        setTimeout(function () {
            document.body.classList.add("body--loaded");
        }, 300);
    });
}

if (typeof WebFontConfig === "undefined" && document.querySelector("[data-loadcss]") !== null) {
    if (typeof document.fonts !== "undefined") {
        document.fonts.ready.then(function () {
            document.documentElement.classList.add("wf-active");
        });

        setTimeout(() => {
            if (!document.documentElement.classList.contains("wf-active")) {
                document.documentElement.classList.add("wf-active");
            }
        }, 500);
    } else {
        document.documentElement.classList.add("wf-active");
    }
}

if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !document.documentElement.classList.contains("ie")) {
    importScript(cdnjs.picturefill);
}

if (typeof document.documentElement.style.grid !== 'string') {
    document.documentElement.classList.add("no-grid");
}

document.addEventListener('lazybeforeunveil', (e) => {
    if (e.target.tagName === 'svg') {
        const imageEl = e.target.querySelector('image');
        if (imageEl) {
            const newImageUrl = imageEl.getAttribute("data-href");
            imageEl.setAttribute('xlink:href', newImageUrl );
            imageEl.remove();
            e.target.insertAdjacentHTML("afterbegin", imageEl.outerHTML);
        }
    }
});